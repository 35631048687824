<template>
<div style="overflow: hidden;">
  <van-nav-bar
      title="i昆明用户登录"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="onBack"
  />
  <div class="van-multi-ellipsis--l3 padding-10" style="text-align: center;margin-top: 200px;">
    {{ message }}</div>
  <van-row v-if="isHome">
    <van-col span="8"></van-col>
    <van-col span="8"><van-button v-if="1==2" icon="wap-home" type="info" to="../index">返回首页</van-button></van-col>
    <van-col span="8"></van-col>
  </van-row>
  <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
</div>
</template>

<script>
import {setKey} from "../../utils/cookie";
import { ikmLogin } from "../../api/ikm";
import Loading from "../../components/logding";

export default {
  name: "index",
  components: { Loading },
  data(){
    return {
      isShow: true,
      isHome: false,
      lodingTxt: '加载中...',
      token: this.$route.query.token,
      message: ''
    }
  },
  created() {
    this.login()
  },
  methods: {
    /**
     * IKM登录
     */
    login(){
      const that = this
      if (!that.token) that.token = ''
      if (that.token == '' || that.token == null){
        that.message = '请求参数错误，不能进行登录！'
        that.isHome = true
        return;
      }
      setKey(process.env.VUE_APP_PLATFORM_COOKIE_KEY,'4')
      that.isShow = true
      ikmLogin(that.token).then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          const data = response.data
          setKey(process.env.VUE_APP_USER_INFO,JSON.stringify(data))
          setKey(process.env.VUE_APP_H5_IS_LOGIN,true)
          setKey(process.env.VUE_APP_USER_ID,data.id)
          setKey(process.env.VUE_APP_USER_NAME,data.username)
          //是否已经注册
          setKey(process.env.VUE_APP_USER_IS_BIND,data.isBind)
          //绑定数量
          setKey(process.env.VUE_APP_USER_BIND_NUMBER,data.waterUserList.length || 0)
          //是否已绑定
          setKey(process.env.VUE_APP_USER_IS_REG,1)
          //是否已实名
          setKey(process.env.VUE_APP_USER_IS_AUTH, data.isAuth)
          if (data.isPc == 1)
            window.location.href = data.pcUrl
          else
            window.location.href = data.to
        }else{
          that.message = response.message
          that.isHome = true
        }
      }).catch(() => {
        that.isShow = false
        that.message = '请求登录失败，请稍后再试！'
        that.isHome = true
      })
    },
    onBack : function (){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>